/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import PageTransition from 'gatsby-plugin-page-transitions';
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import Bckcomp from "./bck"
import { ParallaxProvider } from 'react-scroll-parallax';
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          title2
          menuLinks {
          name
          link
          }
        }
      }
    }
  `)

  return (
    
    <>
    <ParallaxProvider>
   <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title}siteTitle2={data.site.siteMetadata.title2} /> 
       <PageTransition
    defaultStyle={{
      transition: 'opacity 400ms ease-in-out  ',
      opacity: 0,
      position: 'absolute',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: "-100"
    }}
    transitionStyles={{
      entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0, transition: `opacity 500ms ease-in-out`
  ,  },
  exited:  {opacity: 0, transition: `opacity 500ms ease-in-out`, }
    }}
    transitionTime={1500}
  >
    
      
        <main>{children}</main>
       <Footer></Footer>
      </PageTransition></ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
