import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { endianness } from "os"
import {TiHome} from 'react-icons/ti'
import {IoMdCog, IoMdMail} from 'react-icons/io'
import {GiHandBag} from 'react-icons/gi'
import HomeIcon from '@material-ui/icons/Home';
import logo from '../images/gmlogo.png';
class Footer extends React.Component {
  constructor(props){
    super(props)
  this.state = {
    fixed: false,
    showing: false
  };
}



  render() {


    return (
     <div className="normalbox">
     <div className="flex content-around pt-10 ">
         <div className="w-1/2  text-center">
         <Link key={12}  className="self-center  "  to={"/joginyilatkozat"}>
<p className="footertext">Jogi nyilatkozat</p>
                </Link>
         </div>
         <div className=" w-1/2  text-center">
         <Link key={13}  className="self-center  "  to={"/"}>
<p className="footertext">Adatvédelmi Nyilatkozat</p>
                </Link>
         </div></div>
         <div className="block text-center">
         <p className="footertext3">2021 ©  Vezér Optika </p>
         <a key={13}  className="self-center  "  href={"https://martongratzer.dev"}>
<p className="footertext2">Made by MartonGratzer.dev</p>
</a>
     </div>
</div>














    );
  }
}



export default Footer;
