import React from "react"
import GiRoundKnob from 'react-icons/gi'
import AiFillPicture from 'react-icons/gi'
const bckarray=["homebkc","homebkc2","homebkc3","homebkc4"]
const animarray=["kenburns-top","kenburns-top2","kenburns-top3"]
class Bckcomp extends React.Component{



    constructor(props) {
        super(props);
    this.state ={
        bck : "homebkc",
        anim: "kenburns-top"
      }
    
    }

    handlebgchange = () =>{
        let rnd=bckarray[Math.floor(Math.random()*bckarray.length)]
        if (rnd === this.state.bck){
          this.handlebgchange()
        }
        else{
            this.setState({
              bck:rnd 
            });
          }}
          handleanimchange=()=>{
            let rnd=animarray[Math.floor(Math.random()*animarray.length)]
        if (rnd === this.state.anim){
          this.handleanimchange()
        }
        else{
            this.setState({
              anim:rnd 
            });
          }
          }
          render() {

            return (
                null
            )

          }





}
export default Bckcomp

