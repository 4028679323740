import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { endianness } from "os"
import {TiHome} from 'react-icons/ti'
import {IoMdCog, IoMdMail} from 'react-icons/io'
import {GiHandBag} from 'react-icons/gi'
import HomeIcon from '@material-ui/icons/Home';
import logo from '../images/gmlogo.png';
class Header extends React.Component {
  constructor(props){
    super(props)
  this.state = {
    fixed: false,
    showing: false
  };
}



  render() {
    

    const { showing } = this.state;
    const { pages, path, theme, siteTitle, siteTitle2, menuLinks } = this.props;
    return (<>
     
      <nav class="hidden md:flex justify-around   headerbck pt-6 pb-6 w-100">
      
       <div class="  logowidth self-center ">
                <Link key={11}  className="self-center  "  to={"/"}>
<img src={logo} alt="Logo" className=" self-center logoimg" />
                </Link>
                </div>
      {menuLinks.map((link,index) => (
        <div class="self-center">
                <Link key={index} className=" mt-4  headerfont " activeClassName="activeheader"  to={link.link}>
                  {link.name}
                </Link>
                </div>
            ))}
      
      </nav>
      <nav class="flex md:hidden justify-between   headerbck p-2 w-100">
      
       <div class="  logowidth2 self-center ">
                <Link key={11}  className="self-center  "  to={"/"}>
<img src={logo} alt="Logo" className=" self-center logoimg" />
                </Link>
                
                </div>
                <button onClick={() => this.setState({ showing: !showing })}  class="flex items-center  border rounded text-black-200 border-black-400 ">
      <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
    </button></nav>
    {showing
    ? <div className="   flex flex-col  md:hidden">
    
    {menuLinks.map(link => (
      <div className=" bg-white w-100">
                <a  className=""
                  key={link.name}
                  
                >
                  <Link  to={link.link} className=" mt-4  text-lg  maintext2 headerfont " activeClassName="activeheader" >
                    {link.name}
                  </Link>
                </a></div>
              ))}
      
    </div>
    : null}
      </>
    );
  }
}
  


export default Header;
